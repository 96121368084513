<script setup lang="ts">
const props = defineProps<{
  data?: any
  currency?: any
}>()

const { t, n, locale } = useI18n()

const currencyKey = getCurrencyKey(locale.value, props?.currency, true)
</script>

<template>
  <div v-if="props?.data && props?.data?.id">
    <div class="my-5 text-2xl sm:text-xl text-gray-500 font-bold">
      {{ t('order_code') }}:
      <span class="uppercase ml-1">
        {{ props?.data?.id }}
      </span>
    </div>

    <div class="my-5 text-green-600 font-medium text-2xl sm:text-xl">
      {{ t('paid') }}
      <span v-if="props?.data?.total" class="font-bold ml-1">
        {{ n((props?.data?.total ?? 0) * 1, currencyKey ? 'currency' : 'decimal', currencyKey) }}
      </span>
    </div>

    <OrderResultLink :data="props?.data?.id" />
  </div>
</template>
